import { configPrepare } from "@/helpers/vue-good-table"

const i18nPrefix = "ota_management.plan_list.columns"
const defaults = {
  thClass: "table-th"
}

const fields = [
  {
    label: `${i18nPrefix}.status`,
    field: "status"
  },
  {
    label: `${i18nPrefix}.company`,
    field: "company_name"
  },
  {
    label: `${i18nPrefix}.inventory_group`,
    field: "inventory_group_names"
  },
  {
    label: `${i18nPrefix}.shop`,
    field: "shop_names"
  },
  {
    label: `${i18nPrefix}.plan`,
    field: "name"
  },
  {
    label: `${i18nPrefix}.sale_start`,
    field: "sale_start"
  },
  {
    label: `${i18nPrefix}.sale_end`,
    field: "sale_end"
  },
  {
    label: `${i18nPrefix}.post_start`,
    field: "post_start"
  },
  {
    label: `${i18nPrefix}.post_end`,
    field: "post_end"
  },
  {
    label: `${i18nPrefix}.updated_at`,
    field: "updated_at"
  }
]

export const columns = configPrepare(fields, { i18nPrefix, defaults })

export const defaultFiltersObject = () => ({
  companies: [],
  inventoryGroups: [],
  shops: [],
  carClasses: [],
  saleStatus: null,
  dateRange: null,
  periodType: null,
  periodInclusion: null,
  searchValue: ""
})

import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import { mappedDateRange } from "@/config/date_helpers"
import { map, get } from "lodash-es"

import { defaultFiltersObject } from "./helpers"
import extractMutations from "./mutations"
import extractActions from "./actions"

const mapFilters = filters => {
  return {
    shop_ids: map(filters.shops, "id"),
    car_class_ids: map(filters.carClasses, "id"),
    inventory_group_ids: map(filters.inventoryGroups, "id"),
    company_ids: map(filters.companies, "id"),
    released: mapReleased(get(filters, "saleStatus.id")),
    date_range: mappedDateRange(filters.dateRange),
    period_type: filters.periodType?.id,
    period_inclusion_type: filters.periodInclusion?.id,
    search_value: filters.searchValue
  }
}

const mapReleased = status => {
  return !status || status === "all" ? undefined : status === "selling"
}

const baseURI = "/maestro/markups/plans"

const store = new StoreItemsModule({
  baseURI: baseURI,
  presetActions: ["fetch"],
  withFilters: defaultFiltersObject(),
  withSorting: {
    field: "status",
    direction: "desc"
  },
  withPagination: true,
  mapFilters
})

store.mergeState({
  item: {
    publish_settings: {},
    basic_plan_info: {}
  }
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store

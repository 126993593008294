import { isNull, map } from "lodash-es"
import { format as dateFormat } from "date-fns"
import { DATE_FNS_DATE_FORMAT } from "@/config/constants"

export const mappedDateRange = dateRange => {
  return map(dateRange, date => {
    if (isNull(date)) {
      return null
    } else {
      return dateFormat(date, DATE_FNS_DATE_FORMAT)
    }
  })
}
